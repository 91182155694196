footer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: gray;

  & .footer-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: inherit;
  }
}
