.App {
  text-align: center;

  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;

  @keyframes alarm {
    50% {
      background-color: rgb(182, 77, 76);
    }
  }

  &.alarm-active {
    animation: alarm 2s infinite ease-in-out;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px 20px;
    cursor: pointer;
  }

  .global-mute-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75em;
    background: none;
    color: white;
  }

  .time-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .react-tabs__tab-list {
      border: none;
      margin: 0;
      position: absolute;
      bottom: 0;
    }

    .react-tabs__tab {
      border: none;
      bottom: 0;

      &:focus:after {
        display: none;
      }
    }
  }
}
