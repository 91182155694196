.timer {
  .time-inputs {
    font-size: 3em;

    input {
      font-family: inherit;
      font-size: inherit;
      color: inherit;

      border: none;
      box-shadow: inset 0 0 10px #000000;
      border-radius: 10px;

      background: rgba(0, 0, 0, 0.25);

      width: 2ch;
      padding: 0;

      text-align: center;

      /* Hide spinboxes (arrows) */
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        outline: none;
        background: rgba(0, 0, 0, 0.6);
      }

      &::selection {
        background: transparent;
      }
    }
  }
}
