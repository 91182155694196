
.button-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.25em;

  button {
    background: white;
    color: #282c34;

    font-size: 1em;
    width: 25%;
    border: 3px solid white;

    &:hover,
    &:active {
      background: none;
      color: white;
      transition: all 150ms linear;
    }
  }

  button.secondary {
    background: none;
    color: white;

    &:hover,
    &:active {
      background: white;
      color: #282c34;
      transition: all 150ms linear;
    }
  }
}
